import LandingDbSource from "../../data/landingdb-source";
import API_ENDPOINT from "../../globals/api-endpoint";
import {
  editLandingPage,
  editProfilePage,
  editAktivitasPage,
  editMediaPartnerPage,
  editSupportPage,
  editEventPage,
  editPaymentsPage,
  adminTransaksiPage,
  userTransaksiPage,
  ProfileUsersPage,
} from "../component/users/adminCreator";

const editlanding = {
  async render() {
    return `
    <div class="content content-admin-editLanding" id="content" tabindex="0">
    </div>
    `;
  },
  async afterRender() {
    document.title = "EDIT LANDING PAGE";

    try {
      // Render konten halaman
      const content1Source = await LandingDbSource.LandingCrew();
      const content1Container = document.querySelector("#content");
      content1Container.innerHTML = editLandingPage(content1Source);

      // Event listener untuk tombol "Back"
      const backButton = document.querySelector("#backButton");
      backButton.addEventListener("click", () => {
        window.location.hash = "#/admin/dashboard"; // Arahkan kembali ke halaman sebelumnya
      });

      // function drag and drop
      const dropZone = document.querySelector("#dropZone");
      const imageInput = document.querySelector("#imageInput");
      dropZone.addEventListener("click", () => {
        imageInput.click();
      });

      dropZone.addEventListener("dragover", (event) => {
        event.preventDefault();
        dropZone.classList.add("drag-over");
      });

      dropZone.addEventListener("dragleave", () => {
        dropZone.classList.remove("drag-over");
      });

      dropZone.addEventListener("drop", (event) => {
        event.preventDefault();
        dropZone.classList.remove("drag-over");

        const files = event.dataTransfer.files;
        if (files.length) {
          imageInput.files = files; // Assign dropped files to the file input
          dropZone.querySelector(
            "p"
          ).textContent = `File "${files[0].name}" ready for upload.`;
        }
      });

      imageInput.addEventListener("change", () => {
        const file = imageInput.files[0];
        if (file) {
          dropZone.querySelector(
            "p"
          ).textContent = `File "${file.name}" ready for upload.`;
        }
      });

      let currentMode = "add"; // Default mode adalah "add"
      let currentUpdateId = null; // ID data yang akan diupdate (jika dalam mode update)

      // Event listener untuk tombol add data
      const addDataButton = document.querySelector("#addButton");
      const form = document.querySelector("#landingForm");
      const formContainer = document.querySelector("#formContainer");
      const titleInput = document.querySelector("#titleInput");
      // Event listener untuk tombol add data
      addDataButton.addEventListener("click", () => {
        currentMode = "add"; // Ubah mode ke "add"
        formContainer.classList.toggle("d-none");
        form.reset(); // Reset form untuk menghapus data sebelumnya
        dropZone.querySelector("p").textContent =
          "Drag & drop your image here, or click to select a file";
      });

      // Event listener untuk form submit
      form.addEventListener("submit", async (event) => {
        event.preventDefault();

        const imageFile = imageInput.files[0];
        const title = titleInput.value;

        const formData = new FormData();
        formData.append("title", title);
        if (imageFile) {
          formData.append("image", imageFile);
        }

        try {
          let response;

          if (currentMode === "add") {
            // Logic untuk add data
            response = await fetch(API_ENDPOINT.LANDING, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: formData,
            });
          } else if (currentMode === "update") {
            // Logic untuk update data
            response = await fetch(
              `${API_ENDPOINT.LANDING}/${currentUpdateId}`,
              {
                method: "PUT",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: formData,
              }
            );
          }

          if (response.ok) {
            form.reset();
            formContainer.classList.add("d-none");

            window.location.href = `${window.location.origin}${
              window.location.pathname
            }?t=${new Date().getTime()}${window.location.hash}`;
            await this.afterRender();

            await Swal.fire({
              icon: "success",
              title: `Data berhasil ${
                currentMode === "add" ? "ditambahkan" : "diperbarui"
              }!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: `Gagal ${
                currentMode === "add" ? "menambahkan" : "memperbarui"
              } data.`,
            });
          }
        } catch (error) {
          console.error(
            `Error saat ${
              currentMode === "add" ? "menambahkan" : "memperbarui"
            } data:`,
            error
          );
          Swal.fire({
            icon: "error",
            title: "Terjadi kesalahan. Silakan coba lagi.",
          });
        }
      });

      // Event listener untuk tombol update data
      const updateDataButtons = document.querySelectorAll(".update-btn");
      updateDataButtons.forEach((updateButton) => {
        updateButton.addEventListener("click", () => {
          currentMode = "update"; // Ubah mode ke "update"
          currentUpdateId = updateButton.getAttribute("data-id");

          // Ambil data dari contentSource berdasarkan ID
          const updateData = content1Source.find(
            (item) => String(item.id) === currentUpdateId
          );

          if (updateData) {
            titleInput.value = updateData.title;
          } else {
            Swal.fire({
              icon: "error",
              title: "Terjadi kesalahan. Silakan coba lagi.",
            });
          }
          // Tampilkan form update
          formContainer.classList.remove("d-none");
        });
      });

      // Ambil semua tombol dengan ID atau kelas yang relevan
      const deleteDataButtons = document.querySelectorAll(
        "#delete-button-card"
      );
      // Tambahkan event listener ke setiap tombol
      deleteDataButtons.forEach((deleteDataButton) => {
        deleteDataButton.addEventListener("click", async () => {
          const id = deleteDataButton.getAttribute("data-id");

          const confirmDelete = await Swal.fire({
            title: "Apakah Anda yakin ingin menghapus data ini?",
            showCancelButton: true,
            confirmButtonText: "Ya, hapus!",
            cancelButtonText: "Tidak",
          });

          if (!confirmDelete.isConfirmed) return;
          const response = await fetch(`${API_ENDPOINT.LANDING}/${id}`, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          if (response.status === 200) {
            window.location.href = `${window.location.origin}${
              window.location.pathname
            }?t=${new Date().getTime()}${window.location.hash}`;
            await this.afterRender();
            Swal.fire({
              icon: "success",
              title: "Data berhasil dihapus",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Data gagal dihapus!",
            });
          }
        });
      });
    } catch (error) {
      console.error("Error in afterRender:", error);
    }
  },
};

const editProfile = {
  async render() {
    return `
    <div class="content content-admin-profile" id="content" tabindex="0"></div>
    `;
  },
  async afterRender() {
    document.title = "EDIT PROFILE";
    try {
      const contentSource = await LandingDbSource.SourceProfile();
      const content = document.querySelector(".content-admin-profile");
      content.innerHTML = editProfilePage(contentSource);

      // Event listener untuk tombol "Back"
      const backButton = document.querySelector("#backButton");
      backButton.addEventListener("click", () => {
        window.location.hash = "#/admin/dashboard"; // Arahkan kembali ke halaman sebelumnya
      });

      // function drag and drop
      const dropZone = document.querySelector("#dropZone");
      const imageInput = document.querySelector("#imageInput");
      dropZone.addEventListener("click", () => {
        imageInput.click();
      });

      dropZone.addEventListener("dragover", (event) => {
        event.preventDefault();
        dropZone.classList.add("drag-over");
      });

      dropZone.addEventListener("dragleave", () => {
        dropZone.classList.remove("drag-over");
      });

      dropZone.addEventListener("drop", (event) => {
        event.preventDefault();
        dropZone.classList.remove("drag-over");

        const files = event.dataTransfer.files;
        if (files.length) {
          imageInput.files = files; // Assign dropped files to the file input
          dropZone.querySelector(
            "p"
          ).textContent = `File "${files.name}" ready for upload.`;
        }
      });

      imageInput.addEventListener("change", () => {
        const file = imageInput.files[0];
        if (file) {
          dropZone.querySelector(
            "p"
          ).textContent = `File "${file.name}" ready for upload.`;
        }
      });

      let currentMode = "add"; // Default mode adalah "add"
      let currentUpdateId = null; // ID data yang akan diupdate (jika dalam mode update)

      // Event listener untuk tombol add data
      const addDataButton = document.querySelector("#addButton");
      const form = document.querySelector("#landingForm");
      const formContainer = document.querySelector("#formContainer");

      // Input Fields
      const nameInput = document.querySelector("#nameInput");
      const positionInput = document.querySelector("#positionInput");
      const emailInput = document.querySelector("#emailInput");
      const visiMisiInput = document.querySelector("#visiMisiInput");
      const aboutInput = document.querySelector("#aboutInput");

      // Event listener untuk tombol add data
      addDataButton.addEventListener("click", () => {
        currentMode = "add"; // Ubah mode ke "add"
        formContainer.classList.toggle("d-none");
        form.reset(); // Reset form untuk menghapus data sebelumnya
        dropZone.querySelector("p").textContent =
          "Drag & drop your image here, or click to select a file";
      });

      // Form Submit
      form?.addEventListener("submit", async (event) => {
        event.preventDefault();

        // Tampilkan konfirmasi sebelum submit
        const confirmSubmit = await Swal.fire({
          title: "Apakah Anda yakin?",
          text: "Anda akan menyimpan perubahan ini.",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Ya, simpan!",
          cancelButtonText: "Batal",
        });

        if (!confirmSubmit.isConfirmed) {
          // Jika pengguna menekan tombol "Batal", jangan lanjutkan dengan submit
          return;
        }

        // Jika konfirmasi "Ya" dipilih, lanjutkan dengan submit
        const imageFile = imageInput.files[0];
        const formData = new FormData();

        formData.append("name", nameInput.value);
        formData.append("position", positionInput.value);
        formData.append("email", emailInput.value);
        formData.append("visiMisi", visiMisiInput.value);
        formData.append("about", aboutInput.value);
        if (imageFile) formData.append("image", imageFile);

        try {
          let response;
          if (currentMode === "add") {
            response = await fetch(API_ENDPOINT.PROFILE, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: formData,
            });
          } else if (currentMode === "update") {
            response = await fetch(
              `${API_ENDPOINT.PROFILE}/${currentUpdateId}`,
              {
                method: "PUT",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: formData,
              }
            );
          }

          if (response.ok) {
            form.reset();
            formContainer.classList.add("d-none");

            window.location.href = `${window.location.origin}${
              window.location.pathname
            }?t=${new Date().getTime()}${window.location.hash}`;
            await this.afterRender();

            Swal.fire({
              icon: "success",
              title: `Data berhasil ${
                currentMode === "add" ? "ditambahkan" : "diperbarui"
              }!`,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: `Gagal ${
                currentMode === "add" ? "menambahkan" : "memperbarui"
              } data.`,
              showConfirmButton: true,
            });
          }
        } catch (error) {
          console.error(
            `Error saat ${
              currentMode === "add" ? "menambahkan" : "memperbarui"
            } data:`,
            error
          );
          Swal.fire({
            icon: "error",
            title: "Terjadi kesalahan. Silakan coba lagi.",
            showConfirmButton: true,
          });
        }
      });

      // Update Data
      const updateDataButtons = document.querySelectorAll(".update-btn");
      updateDataButtons.forEach((updateButton) => {
        updateButton.addEventListener("click", () => {
          currentMode = "update"; // Ubah mode ke "update"
          currentUpdateId = updateButton.getAttribute("data-id");

          // Ambil data dari contentSource berdasarkan I
          const updateData = contentSource.find(
            (item) => String(item.id) === currentUpdateId
          );

          if (updateData) {
            nameInput.value = updateData.name;
            positionInput.value = updateData.position;
            emailInput.value = updateData.email;
            visiMisiInput.value = updateData.visiMisi;
            aboutInput.value = updateData.about;
          } else {
            console.error("Data untuk update tidak ditemukan.");
          }
          // Tampilkan form update
          formContainer.classList.remove("d-none");
        });
      });

      // Ambil semua tombol dengan ID atau kelas yang relevan
      const deleteDataButtons = document.querySelectorAll(
        "#delete-button-card"
      );

      // Tambahkan event listener ke setiap tombol
      deleteDataButtons.forEach((deleteDataButton) => {
        deleteDataButton.addEventListener("click", async () => {
          const id = deleteDataButton.getAttribute("data-id");

          Swal.fire({
            title: "Apakah Anda yakin?",
            text: "Data ini akan dihapus permanen!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ya, Hapus!",
            cancelButtonText: "Batal",
          }).then(async (result) => {
            if (result.isConfirmed) {
              const response = await fetch(`${API_ENDPOINT.PROFILE}/${id}`, {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              });

              if (response.status === 200) {
                Swal.fire({
                  icon: "success",
                  title: "Data berhasil dihapus!",
                  showConfirmButton: false,
                  timer: 1500,
                });

                window.location.href = `${window.location.origin}${
                  window.location.pathname
                }?t=${new Date().getTime()}${window.location.hash}`;
                await this.afterRender();
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Data gagal dihapus!",
                  showConfirmButton: true,
                });
              }
            }
          });
        });
      });
    } catch (error) {
      console.error("Error in afterRender:", error);
    }
  },
};

const editAktivitas = {
  async render() {
    return `
    <div class="content content-admin-activity" id="content" tabindex="0"></div>
    `;
  },
  async afterRender() {
    document.title = "EDIT ACTIVITY";
    try {
      const contentSource = await LandingDbSource.LandingAct();
      const content = document.querySelector(".content-admin-activity");
      content.innerHTML = editAktivitasPage(contentSource);

      const backButton = document.querySelector("#backButton");
      backButton.addEventListener("click", () => {
        window.location.hash = "#/admin/dashboard";
      });

      // Drag and drop logic
      const dropZone = document.querySelector("#dropZone");
      const imageInput = document.querySelector("#imageInput");
      dropZone.addEventListener("click", () => imageInput.click());
      dropZone.addEventListener("dragover", (event) => {
        event.preventDefault();
        dropZone.classList.add("drag-over");
      });
      dropZone.addEventListener("dragleave", () =>
        dropZone.classList.remove("drag-over")
      );
      dropZone.addEventListener("drop", (event) => {
        event.preventDefault();
        dropZone.classList.remove("drag-over");
        const files = event.dataTransfer.files;
        if (files.length) {
          imageInput.files = files;
          dropZone.querySelector(
            "p"
          ).textContent = `File "${files[0].name}" ready for upload.`;
        }
      });

      imageInput.addEventListener("change", () => {
        const file = imageInput.files[0];
        if (file) {
          dropZone.querySelector(
            "p"
          ).textContent = `File "${file.name}" ready for upload.`;
        }
      });

      let currentMode = "add";
      let currentUpdateId = null;

      const addDataButton = document.querySelector("#addButton");
      const formContainer = document.querySelector("#formContainer");
      const form = document.querySelector("#landingForm");

      const titleInput = document.querySelector("#titleInput");
      const descriptionInput = document.querySelector("#deskripsiInput");
      const tanggalInput = document.querySelector("#tanggalInput");
      const waktuInput = document.querySelector("#waktuInput");

      addDataButton.addEventListener("click", () => {
        currentMode = "add";
        formContainer.classList.toggle("d-none");
      });

      form.addEventListener("submit", async (event) => {
        event.preventDefault();
        const formData = new FormData();
        for (let i = 0; i < imageInput.files.length; i++) {
          formData.append(`image`, imageInput.files[i]);
        }
        formData.append("title", titleInput.value);
        formData.append("description", descriptionInput.value);
        formData.append("date", tanggalInput.value);
        formData.append("time", waktuInput.value);

        try {
          const url =
            currentMode === "add"
              ? API_ENDPOINT.ACT
              : `${API_ENDPOINT.ACT}/${currentUpdateId}`;
          const method = currentMode === "add" ? "POST" : "PUT";
          const response = await fetch(url, {
            method,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: formData,
          });

          if (!response.ok) throw new Error("Failed to save data.");

          Swal.fire({
            icon: "success",
            title: `Data berhasil ${
              currentMode === "add" ? "ditambahkan" : "diperbarui"
            }!`,
            showConfirmButton: false,
            timer: 1500,
          });

          window.location.href = `${window.location.origin}${
            window.location.pathname
          }?t=${new Date().getTime()}${window.location.hash}`;
          await this.afterRender();
        } catch (error) {
          console.error("Submit Error: ", error);
          Swal.fire({
            icon: "error",
            title: "Data gagal disimpan",
            text: error.message,
            showConfirmButton: true,
          });
        }
      });

      const updateButtons = document.querySelectorAll(".update-btn");

      updateButtons.forEach((updateButton) => {
        updateButton.addEventListener("click", () => {
          currentMode = "update"; // Ubah mode ke "update"
          currentUpdateId = updateButton.getAttribute("data-id");

          const updateData = contentSource.find(
            (item) => String(item.id) === currentUpdateId
          );

          if (updateData) {
            titleInput.value = updateData.title;
            descriptionInput.value = updateData.description;
            tanggalInput.value = updateData.date;
            waktuInput.value = updateData.time;
          } else {
            Swal.fire({
              icon: "error",
              title: "Data tidak ditemukan",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          formContainer.classList.remove("d-none");
        });
      });

      const deleteButtons = document.querySelectorAll("#delete-button-card");
      if (deleteButtons.length) {
        deleteButtons.forEach((btn) => {
          btn.addEventListener("click", async () => {
            const id = btn.getAttribute("data-id");

            const result = await Swal.fire({
              icon: "warning",
              title: "Yakin ingin menghapus?",
              showCancelButton: true,
              confirmButtonText: "Ya, hapus!",
              cancelButtonText: "Batal",
            });

            if (result.isConfirmed) {
              try {
                const response = await fetch(`${API_ENDPOINT.ACT}/${id}`, {
                  method: "DELETE",
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                });
                if (!response.ok) throw new Error("Failed to delete.");

                Swal.fire({
                  icon: "success",
                  title: "Data berhasil dihapus!",
                  showConfirmButton: false,
                  timer: 1500,
                });

                window.location.href = `${window.location.origin}${
                  window.location.pathname
                }?t=${new Date().getTime()}${window.location.hash}`;
                await this.afterRender();
              } catch (error) {
                console.error("Delete Error: ", error);
                Swal.fire({
                  icon: "error",
                  title: "Gagal menghapus data",
                  text: error.message,
                  showConfirmButton: true,
                });
              }
            }
          });
        });
      }
    } catch (error) {
      console.error("Error in afterRender: ", error);
    }
  },
};


const editMediaPartner = {
  async render() {
    return `
    <div class="content content-admin-editMediaPartner" id="content" tabindex="0"></div>
    `;
  },
  async afterRender() {
    document.title = "EDIT MEDIA PARTNER";
    try {
      const contentSource = await LandingDbSource.LandingMP();
      const content = document.querySelector("#content");
      content.innerHTML = editMediaPartnerPage(contentSource);

      const backButton = document.querySelector("#backButton");
      backButton.addEventListener("click", () => {
        window.location.hash = "#/admin/dashboard";
      });

      // Drag and drop logic
      const dropZone = document.querySelector("#dropZone");
      const imageInput = document.querySelector("#imageInput");
      dropZone.addEventListener("click", () => {
        imageInput.click();
      });

      dropZone.addEventListener("dragover", (event) => {
        event.preventDefault();
        dropZone.classList.add("drag-over");
      });

      dropZone.addEventListener("dragleave", () => {
        dropZone.classList.remove("drag-over");
      });

      dropZone.addEventListener("drop", (event) => {
        event.preventDefault();
        dropZone.classList.remove("drag-over");

        const files = event.dataTransfer.files;
        if (files.length) {
          imageInput.files = files;
          dropZone.querySelector(
            "p"
          ).textContent = `File "${files[0].name}" ready for upload.`;
        }
      });

      imageInput.addEventListener("change", () => {
        const file = imageInput.files[0];
        if (file) {
          dropZone.querySelector(
            "p"
          ).textContent = `File "${file.name}" ready for upload.`;
        }
      });

      let currentMode = "add";
      let currentUpdateId = null;

      const addDataButton = document.querySelector("#addButton");
      const form = document.querySelector("#landingForm");
      const formContainer = document.querySelector("#formContainer");

      const nameInput = document.querySelector("#nameInput");
      const titleInput = document.querySelector("#titleInput");
      const descriptionInput = document.querySelector("#deskripsiInput");

      addDataButton.addEventListener("click", () => {
        currentMode = "add";
        formContainer.classList.toggle("d-none");
        form.reset();
        dropZone.querySelector("p").textContent =
          "Drag & drop your image here, or click to select a file";
      });

      form?.addEventListener("submit", async (event) => {
        event.preventDefault();

        const imageFile = imageInput.files[0];
        const formData = new FormData();

        formData.append("name", nameInput.value);
        formData.append("title", titleInput.value);
        formData.append("description", descriptionInput.value);
        if (imageFile) formData.append("image", imageFile);

        try {
          let response;
          if (currentMode === "add") {
            response = await fetch(API_ENDPOINT.MP, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: formData,
            });
          } else if (currentMode === "update") {
            response = await fetch(`${API_ENDPOINT.MP}/${currentUpdateId}`, {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: formData,
            });
          }

          if (response.ok) {
            Swal.fire({
              icon: "success",
              title: `Data berhasil ${
                currentMode === "add" ? "ditambahkan" : "diperbarui"
              }!`,
              showConfirmButton: false,
              timer: 1500,
            });

            form.reset();
            formContainer.classList.add("d-none");

            window.location.href = `${window.location.origin}${
              window.location.pathname
            }?t=${new Date().getTime()}${window.location.hash}`;
            await this.afterRender();
          } else {
            Swal.fire({
              icon: "error",
              title: `Gagal ${
                currentMode === "add" ? "menambahkan" : "memperbarui"
              } data.`,
              text: "Silakan periksa input Anda dan coba lagi.",
            });
          }
        } catch (error) {
          console.error(error);
          Swal.fire({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: "Silakan coba lagi nanti.",
          });
        }
      });

      const updateDataButtons = document.querySelectorAll(".update-btn");
      updateDataButtons.forEach((updateButton) => {
        updateButton.addEventListener("click", () => {
          currentMode = "update";
          currentUpdateId = updateButton.getAttribute("data-id");

          const updateData = contentSource.find(
            (item) => String(item.id) === currentUpdateId
          );

          if (updateData) {
            nameInput.value = updateData.name;
            titleInput.value = updateData.title;
            descriptionInput.value = updateData.description;
            formContainer.classList.remove("d-none");
          } else {
            Swal.fire({
              icon: "error",
              title: "Data Tidak Ditemukan",
              text: "Data yang ingin diupdate tidak ditemukan.",
            });
          }
        });
      });

      const deleteButtons = document.querySelectorAll("#delete-button-card");
      if (deleteButtons.length) {
        deleteButtons.forEach((btn) => {
          btn.addEventListener("click", async () => {
            const id = btn.getAttribute("data-id");

            const confirmation = await Swal.fire({
              title: "Apakah Anda yakin?",
              text: "Data yang dihapus tidak dapat dikembalikan!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ya, hapus!",
            });

            if (!confirmation.isConfirmed) return;

            try {
              const response = await fetch(`${API_ENDPOINT.MP}/${id}`, {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              });
              if (!response.ok) throw new Error("Failed to delete.");
              Swal.fire({
                icon: "success",
                title: "Berhasil dihapus!",
                showConfirmButton: false,
                timer: 1500,
              });

              window.location.href = `${window.location.origin}${
                window.location.pathname
              }?t=${new Date().getTime()}${window.location.hash}`;
              await this.afterRender();
            } catch (error) {
              console.error("Delete Error: ", error);
              Swal.fire({
                icon: "error",
                title: "Gagal Menghapus",
                text: "Silakan coba lagi.",
              });
            }
          });
        });
      }
    } catch (error) {
      console.error("Error in afterRender:", error);
      Swal.fire({
        icon: "error",
        title: "Terjadi Kesalahan",
        text: "Tidak dapat memuat data. Silakan coba lagi nanti.",
      });
    }
  },
};


const editSupport = {
  async render() {
    return `
    <div class="content content-admin-editSupport" id="content" tabindex="0"></div>
    `;
  },
  async afterRender() {
    document.title = "EDIT SUPPORT";
    try {
      const contentSource = await LandingDbSource.LandingSP();
      const content = document.querySelector("#content");
      content.innerHTML = editSupportPage(contentSource);

      // Event listener untuk tombol "Back"
      const backButton = document.querySelector("#backButton");
      backButton.addEventListener("click", () => {
        window.location.hash = "#/admin/dashboard"; // Arahkan kembali ke halaman sebelumnya
      });

      // function drag and drop
      const dropZone = document.querySelector("#dropZone");
      const imageInput = document.querySelector("#imageInput");
      dropZone.addEventListener("click", () => {
        imageInput.click();
      });

      dropZone.addEventListener("dragover", (event) => {
        event.preventDefault();
        dropZone.classList.add("drag-over");
      });

      dropZone.addEventListener("dragleave", () => {
        dropZone.classList.remove("drag-over");
      });

      dropZone.addEventListener("drop", (event) => {
        event.preventDefault();
        dropZone.classList.remove("drag-over");

        const files = event.dataTransfer.files;
        if (files.length) {
          imageInput.files = files; // Assign dropped files to the file input
          dropZone.querySelector(
            "p"
          ).textContent = `File "${files[0].name}" ready for upload.`;
        }
      });

      imageInput.addEventListener("change", () => {
        const file = imageInput.files[0];
        if (file) {
          dropZone.querySelector(
            "p"
          ).textContent = `File "${file.name}" ready for upload.`;
        }
      });

      let currentMode = "add"; // Default mode adalah "add"
      let currentUpdateId = null; // ID data yang akan diupdate (jika dalam mode update)

      // Event listener untuk tombol add data
      const addDataButton = document.querySelector("#addButton");
      const form = document.querySelector("#landingForm");
      const formContainer = document.querySelector("#formContainer");

      // Input Fields
      const nameInput = document.querySelector("#nameInput");
      const titleInput = document.querySelector("#titleInput");
      const descriptionInput = document.querySelector("#deskripsiInput");

      // Event listener untuk tombol add data
      addDataButton.addEventListener("click", () => {
        currentMode = "add"; // Ubah mode ke "add"
        formContainer.classList.toggle("d-none");
        form.reset(); // Reset form untuk menghapus data sebelumnya
        dropZone.querySelector("p").textContent =
          "Drag & drop your image here, or click to select a file";
      });

      form?.addEventListener("submit", async (event) => {
        event.preventDefault();

        // Konfirmasi sebelum submit
        const confirmSubmit = await Swal.fire({
          title: "Apakah Anda yakin?",
          text: "Anda akan menyimpan perubahan ini.",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Ya, simpan!",
          cancelButtonText: "Batal",
        });

        if (!confirmSubmit.isConfirmed) {
          // Jika pengguna memilih batal, hentikan submit
          return;
        }

        const imageFile = imageInput.files[0];
        const formData = new FormData();

        formData.append("name", nameInput.value);
        formData.append("title", titleInput.value);
        formData.append("description", descriptionInput.value);
        if (imageFile) formData.append("image", imageFile);

        try {
          let response;
          if (currentMode === "add") {
            response = await fetch(API_ENDPOINT.SP, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: formData,
            });
          } else if (currentMode === "update") {
            response = await fetch(`${API_ENDPOINT.SP}/${currentUpdateId}`, {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: formData,
            });
          }

          if (response.ok) {
            form.reset();
            formContainer.classList.add("d-none");

            window.location.href = `${window.location.origin}${
              window.location.pathname
            }?t=${new Date().getTime()}${window.location.hash}`;
            await this.afterRender();

            Swal.fire({
              icon: "success",
              title: `Data berhasil ${
                currentMode === "add" ? "ditambahkan" : "diperbarui"
              }!`,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: `Gagal ${
                currentMode === "add" ? "menambahkan" : "memperbarui"
              } data.`,
              showConfirmButton: true,
            });
          }
        } catch (error) {
          console.error(
            `Error saat ${
              currentMode === "add" ? "menambahkan" : "memperbarui"
            } data:`,
            error
          );
          Swal.fire({
            icon: "error",
            title: "Terjadi kesalahan. Silakan coba lagi.",
            showConfirmButton: true,
          });
        }
      });

      // Update Data
      const updateDataButtons = document.querySelectorAll(".update-btn");

      updateDataButtons.forEach((updateButton) => {
        updateButton.addEventListener("click", () => {
          currentMode = "update"; // Ubah mode ke "update"
          currentUpdateId = updateButton.getAttribute("data-id");

          // Ambil data dari contentSource berdasarkan ID
          const updateData = contentSource.find(
            (item) => String(item.id) === currentUpdateId
          );

          if (updateData) {
            nameInput.value = updateData.name;
            titleInput.value = updateData.title;
            descriptionInput.value = updateData.description;
          } else {
            console.error("Data untuk update tidak ditemukan.");
          }
          // Tampilkan form update
          formContainer.classList.remove("d-none");
        });
      });

      // Delete Data
      const deleteButtons = document.querySelectorAll("#delete-button-card");
      if (deleteButtons.length) {
        deleteButtons.forEach((btn) => {
          btn.addEventListener("click", async () => {
            const id = btn.getAttribute("data-id");

            // Konfirmasi sebelum menghapus data
            const confirmDelete = await Swal.fire({
              title: "Apakah Anda yakin?",
              text: "Data ini akan dihapus secara permanen.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Ya, hapus!",
              cancelButtonText: "Batal",
            });

            if (!confirmDelete.isConfirmed) return; // Jika dibatalkan, hentikan

            try {
              const response = await fetch(`${API_ENDPOINT.SP}/${id}`, {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              });

              if (response.ok) {
                window.location.href = `${window.location.origin}${
                  window.location.pathname
                }?t=${new Date().getTime()}${window.location.hash}`;
                await this.afterRender();

                Swal.fire({
                  icon: "success",
                  title: "Data berhasil dihapus",
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else {
                throw new Error("Failed to delete.");
              }
            } catch (error) {
              Swal.fire({
                icon: "error",
                title: "Gagal menghapus data",
                text: "Terjadi kesalahan saat menghapus data.",
                showConfirmButton: true,
              });
            }
          });
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  },
};

const editEvent = {
  async render() {
    return `
    <div class="content content-admin-editEvent" id="content" tabindex="0"></div>
    `;
  },
  async afterRender() {
    document.title = "EDIT EVENT";
    try {
      const contentSource = await LandingDbSource.LandingEvents();
      const content = document.querySelector(".content-admin-editEvent");
      content.innerHTML = editEventPage(contentSource);

      const backButton = document.querySelector("#backButton");
      backButton.addEventListener("click", () => {
        window.location.hash = "#/admin/dashboard";
      });

      const dropZone = document.querySelector("#dropZone");
      const imageInput = document.querySelector("#imageInput");
      dropZone.addEventListener("click", () => {
        imageInput.click();
      });

      dropZone.addEventListener("dragover", (event) => {
        event.preventDefault();
        dropZone.classList.add("drag-over");
      });

      dropZone.addEventListener("dragleave", () => {
        dropZone.classList.remove("drag-over");
      });

      dropZone.addEventListener("drop", (event) => {
        event.preventDefault();
        dropZone.classList.remove("drag-over");

        const files = event.dataTransfer.files;
        if (files.length) {
          imageInput.files = files;
          dropZone.querySelector(
            "p"
          ).textContent = `File "${files[0].name}" ready for upload.`;
        }
      });

      imageInput.addEventListener("change", () => {
        const file = imageInput.files[0];
        if (file) {
          dropZone.querySelector(
            "p"
          ).textContent = `File "${file.name}" ready for upload.`;
        }
      });

      let currentMode = "add";
      let currentUpdateId = null;

      const addDataButton = document.querySelector("#addButton");
      const form = document.querySelector("#landingForm");
      const formContainer = document.querySelector("#formContainer");

      const titleInput = document.querySelector("#titleInput");
      const dateInput = document.querySelector("#dateInput");
      const startTimeInput = document.querySelector("#startTimeInput");
      const endTimeInput = document.querySelector("#endTimeInput");
      const priceInput = document.querySelector("#priceInput");
      const venueInput = document.querySelector("#venueInput");
      const addressInput = document.querySelector("#addressInput");
      const ticketsInput = document.querySelector("#ticketsInput");

      addDataButton.addEventListener("click", () => {
        currentMode = "add";
        formContainer.classList.toggle("d-none");
        form.reset();
        dropZone.querySelector("p").textContent =
          "Drag & drop your image here, or click to select a file";
      });

      form?.addEventListener("submit", async (event) => {
        event.preventDefault();

        const imageFile = imageInput.files[0];
        const formData = new FormData();

        formData.append("title", titleInput.value);
        formData.append("date", dateInput.value);
        formData.append("startTime", startTimeInput.value);
        formData.append("endTime", endTimeInput.value);
        formData.append("price", priceInput.value);
        formData.append("venue", venueInput.value);
        formData.append("address", addressInput.value);
        formData.append("availableTickets", ticketsInput.value);
        if (imageFile) formData.append("image", imageFile);

        try {
          let response;
          if (currentMode === "add") {
            response = await fetch(API_ENDPOINT.EVENT, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: formData,
            });
          } else if (currentMode === "update") {
            response = await fetch(`${API_ENDPOINT.EVENT}/${currentUpdateId}`, {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: formData,
            });
          }

          if (response.ok) {
            Swal.fire({
              icon: "success",
              title: `Data berhasil ${
                currentMode === "add" ? "ditambahkan" : "diperbarui"
              }!`,
            });
            form.reset();
            formContainer.classList.add("d-none");
            window.location.href = `${window.location.origin}${
              window.location.pathname
            }?t=${new Date().getTime()}${window.location.hash}`;
            await this.afterRender();
          } else {
            Swal.fire({
              icon: "error",
              title: "Gagal",
              text: `Gagal ${
                currentMode === "add" ? "menambahkan" : "memperbarui"
              } data.`,
            });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Kesalahan",
            text: "Terjadi kesalahan. Silakan coba lagi.",
          });
          console.error(error);
        }
      });

      const updateDataButtons = document.querySelectorAll(".update-btn");
      updateDataButtons.forEach((updateButton) => {
        updateButton.addEventListener("click", () => {
          currentMode = "update";
          currentUpdateId = updateButton.getAttribute("data-id");

          const updateData = contentSource.find(
            (item) => String(item.id) === currentUpdateId
          );

          if (updateData) {
            titleInput.value = updateData.title;
            dateInput.value = updateData.date;
            startTimeInput.value = updateData.startTime;
            endTimeInput.value = updateData.endTime;
            priceInput.value = updateData.price;
            venueInput.value = updateData.venue;
            addressInput.value = updateData.address;
            ticketsInput.value = updateData.availableTickets;
          } else {
            Swal.fire({
              icon: "error",
              title: "Data Tidak Ditemukan",
              text: "Data yang ingin diupdate tidak ditemukan.",
            });
          }
          formContainer.classList.remove("d-none");
        });
      });

      const deleteButtons = document.querySelectorAll("#delete-button-card");
      deleteButtons.forEach((btn) => {
        btn.addEventListener("click", async () => {
          const id = btn.getAttribute("data-id");
          Swal.fire({
            title: "Yakin ingin menghapus?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Hapus",
            cancelButtonText: "Batal",
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                const response = await fetch(`${API_ENDPOINT.EVENT}/${id}`, {
                  method: "DELETE",
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                });
                if (response.ok) {
                  Swal.fire("Berhasil!", "Data telah dihapus.", "success");
                  window.location.href = `${window.location.origin}${
                    window.location.pathname
                  }?t=${new Date().getTime()}${window.location.hash}`;
                  await this.afterRender();
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Gagal",
                    text: "Gagal menghapus data.",
                  });
                }
              } catch (error) {
                Swal.fire({
                  icon: "error",
                  title: "Kesalahan",
                  text: "Terjadi kesalahan. Silakan coba lagi.",
                });
                console.error(error);
              }
            }
          });
        });
      });
    } catch (error) {
      console.error("Error in afterRender:", error);
    }
  },
};

const editpayments = {
  async render() {
    return `
    <div class="content content-admin-edit-payments" id="content" tabindex="0"></div>
    `;
  },

  async afterRender() {
    document.title = "EDIT PAYMENTS";
    try {
      const contentSource = await LandingDbSource.AllPayment();
      const content = document.querySelector(".content-admin-edit-payments");
      content.innerHTML = editPaymentsPage(contentSource);

      // Event listener untuk tombol "Back"
      const backButton = document.querySelector("#backButton");
      backButton.addEventListener("click", () => {
        window.location.hash = "#/admin/dashboard"; // Arahkan kembali ke halaman sebelumnya
      });

      // Function drag and drop
      const dropZone = document.querySelector("#dropZone");
      const imageInput = document.querySelector("#imageInput");
      dropZone.addEventListener("click", () => {
        imageInput.click();
      });

      dropZone.addEventListener("dragover", (event) => {
        event.preventDefault();
        dropZone.classList.add("drag-over");
      });

      dropZone.addEventListener("dragleave", () => {
        dropZone.classList.remove("drag-over");
      });

      dropZone.addEventListener("drop", (event) => {
        event.preventDefault();
        dropZone.classList.remove("drag-over");

        const files = event.dataTransfer.files;
        if (files.length) {
          imageInput.files = files; // Assign dropped files to the file input
          dropZone.querySelector(
            "p"
          ).textContent = `File "${files[0].name}" ready for upload.`;
        }
      });

      imageInput.addEventListener("change", () => {
        const file = imageInput.files[0];
        if (file) {
          dropZone.querySelector(
            "p"
          ).textContent = `File "${file.name}" ready for upload.`;
        }
      });

      let currentMode = "add";
      let currentUpdateId = null;

      // Event listener untuk tombol add data
      const addDataButton = document.querySelector("#addButton");
      const form = document.querySelector("#landingForm");
      const formContainer = document.querySelector("#formContainer");

      // Input Fields
      const bankNameInput = document.querySelector("#bankNameInput");
      const accountNumberInput = document.querySelector("#accountNumberInput");
      const accountHolderInput = document.querySelector("#accountHolderInput");

      addDataButton.addEventListener("click", () => {
        currentMode = "add";
        formContainer.classList.toggle("d-none");
        form.reset();
        dropZone.querySelector("p").textContent =
          "Drag & drop your image here, or click to select a file";
      });

      form?.addEventListener("submit", async (event) => {
        event.preventDefault();

        const imageFile = imageInput.files[0];
        const formData = new FormData();

        formData.append("bankName", bankNameInput.value);
        formData.append("accountNumber", accountNumberInput.value || null);
        formData.append("accountHolder", accountHolderInput.value);
        if (imageFile) formData.append("image", imageFile);

        try {
          let response;
          if (currentMode === "add") {
            response = await fetch(API_ENDPOINT.PAYMENT, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: formData,
            });
          } else if (currentMode === "update") {
            response = await fetch(
              `${API_ENDPOINT.PAYMENT}/${currentUpdateId}`,
              {
                method: "PUT",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: formData,
              }
            );
          }

          if (response.ok) {
            Swal.fire(
              "Success!",
              `Data berhasil ${
                currentMode === "add" ? "ditambahkan" : "diperbarui"
              }!`,
              "success"
            );
            form.reset();
            formContainer.classList.add("d-none");

            window.location.href = `${window.location.origin}${
              window.location.pathname
            }?t=${new Date().getTime()}${window.location.hash}`;
            await this.afterRender();
          } else {
            Swal.fire(
              "Error!",
              `Gagal ${
                currentMode === "add" ? "menambahkan" : "memperbarui"
              } data.`,
              "error"
            );
          }
        } catch (error) {
          console.error("Error:", error);
          Swal.fire("Error!", "Terjadi kesalahan. Silakan coba lagi.", "error");
        }
      });

      // Tombol Update
      const updateButtons = document.querySelectorAll(".update-btn");
      updateButtons.forEach((button) => {
        button.addEventListener("click", () => {
          currentMode = "update";
          currentUpdateId = button.dataset.id;

          const paymentData = contentSource.find(
            (payment) => String(payment.id) === currentUpdateId
          );

          if (paymentData) {
            bankNameInput.value = paymentData.bankName;
            accountNumberInput.value = paymentData.accountNumber;
            accountHolderInput.value = paymentData.accountHolder;
            formContainer.classList.remove("d-none");
          } else {
            console.error("Data untuk update tidak ditemukan.");
          }
        });
      });

      // Tombol Delete
      const deleteButtons = document.querySelectorAll(".delete-btn");
      deleteButtons.forEach((deleteDataButton) => {
        deleteDataButton.addEventListener("click", async () => {
          const id = deleteDataButton.getAttribute("data-id");

          const result = await Swal.fire({
            title: "Apakah Anda yakin?",
            text: "Data yang dihapus tidak dapat dikembalikan!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ya, hapus!",
            cancelButtonText: "Batal",
          });

          if (result.isConfirmed) {
            try {
              const response = await fetch(`${API_ENDPOINT.PAYMENT}/${id}`, {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              });

              if (response.status === 200) {
                Swal.fire("Deleted!", "Data berhasil dihapus.", "success");
                window.location.href = `${window.location.origin}${
                  window.location.pathname
                }?t=${new Date().getTime()}${window.location.hash}`;
                await this.afterRender();
              } else {
                Swal.fire("Error!", "Data gagal dihapus.", "error");
              }
            } catch (error) {
              console.error("Delete Error:", error);
              Swal.fire(
                "Error!",
                "Terjadi kesalahan saat menghapus data.",
                "error"
              );
            }
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  },
};

const adminTransaksi = {
  async render() {
    return `
    <div class="content content-admin-edit-transaksi" id="content" tabindex="0"></div>
    `;
  },
  async afterRender() {
    document.title = "TRANSAKSI";

    const loadOrders = async (status = "") => {
      try {
        const contentSource = await LandingDbSource.allOrder(status);
        const content = document.querySelector(".content-admin-edit-transaksi");

        // Kirim status ke fungsi adminTransaksiPage agar dropdown tetap menampilkan status yang dipilih
        content.innerHTML = adminTransaksiPage(contentSource, status);

        const backButton = document.querySelector("#backButton");
        backButton.addEventListener("click", () => {
          window.location.hash = "#/admin/dashboard";
        });

        const filterStatus = document.querySelector("#filterStatus");
        filterStatus.addEventListener("change", async (event) => {
          const newStatus = event.target.value;
          await loadOrders(newStatus);
        });

        // Update Data
        const updateDataButtons = document.querySelectorAll(".update-btn");
        updateDataButtons.forEach((updateButton) => {
          updateButton.addEventListener("click", async () => {
            const currentUpdateId = updateButton.getAttribute("data-id");
            const currentStatus = updateButton.getAttribute("data-status");

            try {
              let newStatus;
              switch (currentStatus) {
                case "pending":
                  newStatus = "paid";
                  break;
                case "paid":
                  newStatus = "processing";
                  break;
                case "processing":
                  newStatus = "success";
                  break;
                case "success":
                case "cancelled":
                  Swal.fire("Info", "Status tidak dapat diubah lagi.", "info");
                  return;
                default:
                  throw new Error("Status tidak valid");
              }

              const result = await Swal.fire({
                title: "Konfirmasi Perubahan Status",
                text: `Apakah Anda yakin ingin mengubah status ke "${newStatus}"?`,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Ya, Ubah",
                cancelButtonText: "Batal",
              });

              if (result.isConfirmed) {
                const updateData = await fetch(
                  `${API_ENDPOINT.ORDER}/${currentUpdateId}`,
                  {
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    body: JSON.stringify({ status: newStatus }),
                  }
                );

                if (updateData.ok) {
                  Swal.fire("Sukses", "Status berhasil diperbarui.", "success");
                  await loadOrders(status);
                } else {
                  throw new Error("Gagal memperbarui status.");
                }
              }
            } catch (error) {
              console.error("Error updating status:", error);
              Swal.fire(
                "Error",
                `Gagal memperbarui status: ${error.message}`,
                "error"
              );
            }
          });
        });

        // Cancel Data
        const cancelDataButtons = document.querySelectorAll(".cancel-btn");
        cancelDataButtons.forEach((cancelButton) => {
          cancelButton.addEventListener("click", async () => {
            const currentUpdateId = cancelButton.getAttribute("data-id");
            const currentStatus = cancelButton.getAttribute("data-status");

            try {
              let newStatus;
              if (["pending", "paid", "processing"].includes(currentStatus)) {
                newStatus = "cancelled";
              } else {
                Swal.fire("Info", "Status tidak dapat dibatalkan.", "info");
                return;
              }

              const result = await Swal.fire({
                title: "Konfirmasi Pembatalan",
                text: "Apakah Anda yakin ingin membatalkan transaksi ini?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ya, Batalkan",
                cancelButtonText: "Batal",
              });

              if (result.isConfirmed) {
                const updateData = await fetch(
                  `${API_ENDPOINT.ORDER}/${currentUpdateId}`,
                  {
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    body: JSON.stringify({ status: newStatus }),
                  }
                );

                if (updateData.ok) {
                  Swal.fire(
                    "Sukses",
                    "Transaksi berhasil dibatalkan.",
                    "success"
                  );
                  await loadOrders(status);
                } else {
                  throw new Error("Gagal membatalkan transaksi.");
                }
              }
            } catch (error) {
              console.error("Error cancelling transaction:", error);
              Swal.fire(
                "Error",
                `Gagal membatalkan transaksi: ${error.message}`,
                "error"
              );
            }
          });
        });
      } catch (error) {
        Swal.fire(
          "Error",
          `Gagal memuat data transaksi: ${error.message}`,
          "error"
        );
      }
    };

    try {
      // Muat semua transaksi saat halaman pertama kali dibuka
      await loadOrders();
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  },
};

const userTransaksi = {
  async render() {
    return `
    <div class="content content-user-transaksi" id="content" tabindex="0"></div>
    `;
  },
  async afterRender() {
    document.title = "TRANSAKSI";

    const loadOrders = async (status = "") => {
      try {
        const contentSource = await LandingDbSource.allOrderUser(status);
        const content = document.querySelector(".content-user-transaksi");

        // Kirim status ke fungsi userTransaksiPage agar dropdown tetap menampilkan status yang dipilih
        content.innerHTML = userTransaksiPage(contentSource, status);

        const backButton = document.querySelector("#backButton");
        backButton.addEventListener("click", () => {
          window.location.hash = "#/user/dashboard";
        });

        const filterStatus = document.querySelector("#filterStatus");
        filterStatus.addEventListener("change", async (event) => {
          const newStatus = event.target.value;
          await loadOrders(newStatus);
        });

        // Update Data
        const updateDataButtons = document.querySelectorAll(".update-btn");
        updateDataButtons.forEach((updateButton) => {
          updateButton.addEventListener("click", async () => {
            const currentUpdateId = updateButton.getAttribute("data-id");
            const currentStatus = updateButton.getAttribute("data-status");

            try {
              let newStatus;
              switch (currentStatus) {
                case "pending":
                  newStatus = "paid";
                  break;
                case "success":
                case "cancelled":
                  Swal.fire("Info", "Status tidak dapat diubah lagi.", "info");
                  return;
                default:
                  throw new Error("Status tidak valid");
              }

              const result = await Swal.fire({
                title: "Konfirmasi Perubahan Status",
                text: `Apakah Anda yakin ingin mengubah status ke "${newStatus}"?`,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Ya, Ubah",
                cancelButtonText: "Batal",
              });

              if (result.isConfirmed) {
                await fetch(`${API_ENDPOINT.ORDER}/${currentUpdateId}`, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                  body: JSON.stringify({ status: newStatus }),
                });

                Swal.fire("Sukses", "Status berhasil diperbarui.", "success");
                await loadOrders(status);
              }
            } catch (error) {
              console.error("Error updating status:", error);
              Swal.fire(
                "Error",
                `Gagal memperbarui status: ${error.message}`,
                "error"
              );
            }
          });
        });

        // Cancel Data
        const cancelDataButtons = document.querySelectorAll(".cancel-btn");
        cancelDataButtons.forEach((cancelButton) => {
          cancelButton.addEventListener("click", async () => {
            const currentUpdateId = cancelButton.getAttribute("data-id");
            const currentStatus = cancelButton.getAttribute("data-status");

            try {
              let newStatus;
              if (currentStatus === "pending") {
                newStatus = "cancelled";
              } else {
                Swal.fire("Info", "Status tidak dapat dibatalkan.", "info");
                return;
              }

              const result = await Swal.fire({
                title: "Konfirmasi Pembatalan",
                text: "Apakah Anda yakin ingin membatalkan transaksi ini?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ya, Batalkan",
                cancelButtonText: "Batal",
              });

              if (result.isConfirmed) {
                await fetch(`${API_ENDPOINT.ORDER}/${currentUpdateId}`, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                  body: JSON.stringify({ status: newStatus }),
                });

                Swal.fire(
                  "Sukses",
                  "Transaksi berhasil dibatalkan.",
                  "success"
                );
                await loadOrders(status);
              }
            } catch (error) {
              console.error("Error cancelling transaction:", error);
              Swal.fire(
                "Error",
                `Gagal membatalkan transaksi: ${error.message}`,
                "error"
              );
            }
          });
        });
      } catch (error) {
        Swal.fire(
          "Error",
          `Gagal memuat data transaksi: ${error.message}`,
          "error"
        );
      }
    };

    try {
      // Muat semua transaksi saat halaman pertama kali dibuka
      await loadOrders();
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  },
};

const ProfileUsers = {
  async render() {
    return `
    <div class="content content-profile-users" id="content" tabindex="0">
    </div>
    `;
  },
  async afterRender() {
    document.title = "USER PROFILE";

    try {
      const contentContainer = document.querySelector("#content");
      contentContainer.innerHTML = ProfileUsersPage();
    } catch (error) {
      
    }
  },
};


export {
  editlanding,
  editProfile,
  editAktivitas,
  editMediaPartner,
  editSupport,
  editEvent,
  editpayments,
  adminTransaksi,
  userTransaksi,
  ProfileUsers,
};
